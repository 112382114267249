//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AuthForm from "@/components/Auth/Form";
import Logo from "@/views/public/common/P/Logo.vue";
export default {
  meta() {
    const name = this.title;
    const meta_description = this.meta_description; //!&:
    return {
      title: name ? `${name}` : undefined,
      ////&: title: name ? `SalesFinder - ${ name }` : undefined,
      meta: [{
        name: "description",
        content: `${meta_description}`
      }, {
        name: "viewport",
        content: "width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0"
      }]
    };
  },
  data() {
    return {
      yourValue: ""
    };
  },
  computed: {
    action() {
      return this.$route.meta.action;
    },
    title() {
      if (this.action == "login") return "客户登录"; //@V: '登录'
      if (this.action == "register") return "注册"; //@V: '注册'
      if (this.action == "restorepassword") return "找回密码"; //@V:'找回密码'
      return "";
    },
    //!&:
    meta_description() {
      if (this.action == "login") return "SalesFinder 服务客户登录";
      if (this.action == "register") return "免费注册 SalesFinder 服务并访问该服务的所有分析工具";
      if (this.action == "restorepassword") return "忘记了您的 SalesFinder 帐户密码? 填写恢复表格";
      return "";
    },
    formClass() {
      if (this.action == "login" && this.$mq !== "mobile") return "mt100";
      if (this.action == "register" && this.$mq !== "mobile") return "mt60";
      if (this.action == "restorepassword" && this.$mq !== "mobile") return "mt120";
      return "";
    }
  },
  components: {
    AuthForm,
    Logo
  }
};